<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>
          {{ $t("countries.deliveries") }}
        </h4>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-type-4 btn-small"
          v-on:click="deliveryMethodsModal"
        >
          <span>
            {{ $t("deliveries.deliveryMethods") }}
          </span>
        </button>
      </div>
    </div>
    <div v-for="country in countries" :key="country.id">
      <b>{{ country.name }}</b>
      <OneByOneTable
        v-if="currencies.length > 0 && deliveries.length > 0"
        v-model:rows="deliveryRows"
        v-model:items="country.deliveryMethodPrices"
        type="delivery"
        :empty-line="true"
        :show-add="false"
        :key="key"
        v-on:delete="(d) => deleteRow(country.id, d)"
        v-on:update:field="(d, e, f) => update(country.id, d, e, f)"
        v-on:add:field="(e, f) => add(country.id, e, f)"
      />
    </div>
    <DeliveriesModal ref="deliveriesModal" :deliveries="deliveries" />
    <ConfirmModal
      ref="deleteModal"
      :title="$t(`deliveries.deleteDeliveryPrice`)"
      :text="`<ul><li>You will delete a delivery price<b></li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
      cancel-button="No, keep the price"
      ok-button="Yes, delete the price"
      v-on:success="deletePrice"
    />
  </div>
</template>

<script>
import http from "@/modules/http";
import OneByOneTable from "@/components/lists/OneByOneTable";
import { useStore } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal";
import DeliveriesModal from "@/components/modals/DeliveriesModal";

export default {
  name: "Deliveries",
  components: { DeliveriesModal, ConfirmModal, OneByOneTable },
  data() {
    return {
      store: useStore(),
      key: 0,
      countries: [],
      deliveries: [],
      deleteItem: null,
      deliveryRows: [
        {
          name: "Name",
          key: "delivery_method_id",
          type: "enum",
          label: "name",
          selectKey: "id",
          values: [],
          show: true,
        },
        {
          name: "Type",
          key: "type",
          type: "enum",
          label: "name",
          editable: false,
          values: {
            1: { id: 1, name: "Normal delivery" },
            2: { id: 2, name: "Pick-up point" },
            3: { id: 3, name: "Return" },
            4: { id: 4, name: "Change" },
            5: { id: 5, name: "Next day delivery" },
            6: { id: 6, name: "Saturday delivery" },
            7: { id: 7, name: "Flexible day delivery" },
            8: { id: 8, name: "Timeslot delivery" },
          },
          show: true,
        },
        {
          name: "Currency",
          key: "currency_code",
          type: "enum",
          values: [],
          label: "code",
          selectKey: "code",
          show: true,
        },
        {
          name: "Price",
          key: "price",
          type: "number",
          show: true,
        },
        {
          name: "Min order amount",
          key: "min_order_amount",
          type: "number",
          show: true,
        },
        {
          name: "Min ordered item count",
          key: "min_order_count",
          type: "number",
          show: true,
        },
        {
          name: "Warehouse restriction",
          key: "warehouse_id",
          type: "enum",
          values: [],
          label: "name",
          selectKey: "id",
          show: true,
        },
        {
          name: "Pickup point type restriction",
          key: "delivery_point_type",
          type: "enum",
          values: [
            { id: null, name: " - " },
            { id: 1, name: "Pick-up point" },
            { id: 2, name: "Post office" },
            { id: 3, name: "Parcel automate" },
          ],
          label: "name",
          selectKey: "id",
          show: true,
        },
        {
          name: "In delivery",
          key: "is_delivery",
          type: "boolean",
          show: true,
        },
        {
          name: "In return",
          key: "is_return",
          type: "boolean",
          show: true,
        },
      ],
    };
  },
  computed: {
    currencies() {
      return this.store.state.currencies;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
  },
  mounted() {
    this.load();
    this.deliveryRows[2].values = this.currencies;
    this.deliveryRows[6].values = this.warehouses;
  },
  methods: {
    load() {
      http.fetch("/deliveries/methods").then((data) => {
        this.deliveries = data;
        this.deliveryRows[0].values = data;
        this.key++;
      });
      http.fetch("/countries/details").then((data) => {
        this.countries = data;
      });
    },
    update(country, data, key, val) {
      if (key === "delivery_method_id") {
        data["type"] = this.deliveries.find((e) => e.id === val)?.type;
      }
      data.country_id = country;

      if (data.price_id === 0) {
        if (
          data.delivery_method_id > 0 &&
          data.currency_code !== undefined &&
          data.price !== undefined
        ) {
          let e = data;
          delete data.price_id;
          http.fetch("/deliveries/methods", data, true).then((data) => {
            e.price_id = data.id;
          });
        }
      } else {
        let send = {};
        send[key] = val;

        http.fetch("/deliveries/methods/" + data.price_id, send, true, "PUT");
      }
    },
    add(country, key, val) {
      let row = { price_id: 0 };
      row[key] = val;

      if (key === "delivery_method_id") {
        row["type"] = this.deliveries.find((e) => e.id === val)?.type;
      }

      country = this.countries.find((e) => e.id === country);

      if (key !== "currency_code") {
        if (country?.webshop?.currency_code) {
          row["currency_code"] = country.webshop.currency_code;
        } else if (
          this.currencies.findIndex(
            (e) => e.code === country.default_currency
          ) > -1
        ) {
          row["currency_code"] = country.default_currency;
        }
      }

      country.deliveryMethodPrices.push(row);
    },
    deleteRow(country, e) {
      this.deleteItem = e;
      this.deleteItem["country_id"] = country;
      this.$refs.deleteModal.showModal();
    },
    deliveryMethodsModal() {
      this.$refs.deliveriesModal.showModal();
    },
    deletePrice() {
      http
        .fetch(
          "/deliveries/methods/" + this.deleteItem.price_id,
          {},
          true,
          "DELETE"
        )
        .then(() => {
          let list = this.countries.find(
            (e) => e.id === this.deleteItem["country_id"]
          )?.deliveryMethodPrices;
          let e = list.indexOf(this.deleteItem);
          if (e > -1) {
            list.splice(e, 1);
          }
          this.key++;
        });
    },
  },
};
</script>
